import React, { useState, useEffect } from "react";
import * as styleSheet from "./index.module.scss"

const Hours = ({ locationHours, timezone = "Central Standard Time", passedStyles, locationTitle }) => {
	const [isOpen, setIsOpen] = useState(null);
	const [currentTime, setCurrentTime] = useState(null);
	const [currentDay, setCurrentDay] = useState(null);
	const [closeTime, setCloseTime] = useState(null);
	const [openTime, setOpenTime] = useState(null);
	const [openDay, setOpenDay] = useState(null);

	const getCurrentDayAndTime = async () => {
		let locationTimezone = "America/Chicago";

		switch (timezone) {
			case "Alaska Standard Time":
				locationTimezone = "America/Anchorage";
				break;
			case "Alaska Daylight Time":
				locationTimezone = "America/Anchorage";
				break;
			case "Hawaii-Aleutian Standard Time":
				locationTimezone = "Pacific/Honolulu";
				break;
			case "Hawaii-Aleutian Daylight Time":
				locationTimezone = "Pacific/Honolulu";
				break;
			case "Central Standard Time":
				locationTimezone = "America/Chicago";
				break;
			case "Central Daylight Time":
				locationTimezone = "America/Chicago";
				break;
			case "Eastern Standard Time":
				locationTimezone = "America/New_York";
				break;
			case "Eastern Daylight Time":
				locationTimezone = "America/New_York";
				break;
			case "Mountain Standard Time":
				locationTimezone = "America/Denver";
				break;
			case "Mountain Daylight Time":
				locationTimezone = "America/Denver";
				break;
			case "Pacific Standard Time":
				locationTimezone = "America/Los_Angeles";
				break;
			case "Pacific Daylight Time":
				locationTimezone = "America/Los_Angeles";
				break;
			default:
				locationTimezone = "America/Chicago";
		}

		const currentdateTimeFormat = new Intl.DateTimeFormat('en-US', {
			timeZone: locationTimezone,
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: '2-digit',
			minute: 'numeric',
			second: 'numeric',
		});
		const currentTime = currentdateTimeFormat.format(new Date());
		setCurrentTime(new Date(currentTime).getTime());

		let currentDay = new Date(currentTime).getDay();
		currentDay = currentDay <= 0 ? 6 : currentDay - 1; // Javascript has 0 as Sunday, but Birdeye has Monday as 0

		setCurrentDay(currentDay);
	};

	const getNow = () => {
		const currentHour = new Date(currentTime).getHours();
		const currentMinute = new Date(currentTime).getMinutes();
		const now = Number('' + currentHour + (currentMinute < 10 ? ('0' + currentMinute) : currentMinute));
		return now;
	};

	const getNextOpenDay = () => {
		let day = null;
		const now = getNow();

		// Loop through all weekdays
		for (let i = 0; i < 7; i++) {
			// Set the earliest day that is open by default
			if (day === null && locationHours[i].isOpen) {
				day = i;
				setOpenTime(locationHours[i].workingHours[0].startHour);
			}
			
			// If location has hours on the day being reviewed
			if (locationHours[i] && locationHours[i].isOpen) {
				
				// If today is the day being checked
				if (i === currentDay) {
					locationHours[i].workingHours.forEach((hours) => {
						if( Number(hours.startHour.replace(":", "") > now) ) {
							day = i;
							setOpenTime(hours.startHour);
						} else {
							if (day === i) {
								day = null;
								setOpenTime(null);
							}
						}
					});
				} else {
					// If today is not the day being checked
					if (i > currentDay) {
						day = i;
						setOpenTime(locationHours[i].workingHours[0].startHour);
						break;
					}
				}
			}
		}
			
		switch (day) {
			case 0:
				setOpenDay("Monday");
				break;
			case 1:
				setOpenDay("Tuesday");
				break;
			case 2:
				setOpenDay("Wednesday");
				break;
			case 3:
				setOpenDay("Thursday");
				break;
			case 4:
				setOpenDay("Friday");
				break;
			case 5:
				setOpenDay("Saturday");
				break;
			case 6:
				setOpenDay("Sunday");
				break;
			default:
				break;
		}
	}

	const checkIfopen = async () => {
		const todaysHours = locationHours[currentDay];

		if (!todaysHours) {
			// Leave as null, as this location doesn't utilize hours
			return;
		}

		if (todaysHours.isOpen === false || todaysHours.workingHours === null) {
			// Location is closed on this day, find next open day
			setIsOpen(false);
			getNextOpenDay();
			return;
		}

		// Location has hours set for this day
		let open = false;
		let closesAt = null;
		const now = getNow();
		
		todaysHours.workingHours.forEach((interval) => {
			// Loop through open intervals and see if current time falls within any of them
			if (interval.startHour && interval.endHour) {
				const start = Number(interval.startHour.replace(":", ""));
				const end = Number(interval.endHour.replace(":", ""));
				if (start <= now && end >= now) {
					open = true;
					closesAt = interval.endHour;
				} 
			}
		});

		setIsOpen(open);

		if (open) {
			setCloseTime(closesAt);
		} else {
			getNextOpenDay();
		}
	};


	const convertMilitaryTimeStringToTwelveHour = (time) => {
		// Check correct time format and split into components
		time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
			
		if (time.length > 1) { // If time format correct
			time = time.slice(1);  // Remove full string match value
			time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
			time[0] = +time[0] % 12 || 12; // Adjust hours
		}
		return time.join(''); // return adjusted time or original string
	};
	
	useEffect(() => {
		getCurrentDayAndTime();
	}, []);

	useEffect(() => {
		if (currentTime !== null && currentDay !== null) {
			checkIfopen();
		}
	}, [currentTime, currentDay]);

	return (
		<div className={passedStyles ? passedStyles.Hours : styleSheet.Hours}>
			{isOpen !== null && <span className={passedStyles ? passedStyles.status : styleSheet.status}>{isOpen ? "Open" : "Closed"}</span>}
			{closeTime !== null && <span className={passedStyles ? passedStyles.change : styleSheet.change}>Closes: {convertMilitaryTimeStringToTwelveHour(closeTime)}</span>}
			{ openTime !== null && <span className={passedStyles ? passedStyles.change : styleSheet.change}>Opens: {convertMilitaryTimeStringToTwelveHour(openTime)} {openDay}</span> }
		</div>
	);
};

export default Hours;
