import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { enews as styleSheet} from "./index.module.scss"

const Enews = () => {
	const [formresponse, setFormresponse] = useState( '' );
	const [showform, setShowform] = useState( true );
	
	const { register, handleSubmit, formState: { errors } } = useForm();
	const onSubmit = async(data) => { 
		setShowform(false);
		try {
			const url = "" + process.env.GATSBY_WP_BASE_URL + "/wp-json/perch_endpoint/v1/submit_enews_entry";

			await fetch(url, { 
				method: 'post', 
				headers: new Headers({
					'Content-Type': 'application/x-www-form-urlencoded'
				}), 
				body: xwwwfurlenc(data)
			}).then(async function(response){
				const message = await response.json();

				if( message.response.code === 200 ){
					const jsonMessage = await JSON.parse(message.body);
					if( jsonMessage && jsonMessage['confirmation_message']){
						setFormresponse(jsonMessage['confirmation_message']);
					} else {
						setFormresponse("<p>Thanks for submitting your entry! We will get in touch with you shortly.");
					}
					
				} else {
					setFormresponse("<p>Oops! There has been an error. Please make sure all fields are entered correctly.</p>");
					setShowform(true);
				}
				
			});
		} catch (error) {
			console.log(error);
			setFormresponse("<p>Oops! There has been an error. Please try again soon.</p>");
		}
	};

	function xwwwfurlenc(srcjson){
		if(typeof srcjson !== "object"){
			if(typeof console !== "undefined"){
				return null;
			}
		}
		var u = encodeURIComponent;
		var urljson = "";
		var keys = Object.keys(srcjson);
		for(var i=0; i <keys.length; i++){
			urljson += u(keys[i]) + "=" + u(srcjson[keys[i]]);
			if(i < (keys.length-1))urljson+="&";
		}
		return urljson;
	}

	return (
		<>
			{
			showform ? <form className={styleSheet} onSubmit={handleSubmit(onSubmit)}>
				<label htmlFor="input_1">Full Name</label>
				<input 
					htmlid="input_1"
					className={styleSheet.input} 
					name="input_1" 
					type="text" 
					placeholder="Full Name" 
					aria-invalid={ (errors !== undefined) && errors.input_2 ? 'true' : 'false'}
					aria-describedby="error-name-required"
					{...register('input_1', { required: true })}  />
					{ (errors !== undefined) && errors.input_1 && errors.input_1.type === "required" && (
					<span role="alert" id="error-name-required">This field is required.</span>
				)}
				<label htmlFor="input_2">Email Address</label>
				<input 
					htmlid="input_2"
					className={styleSheet.input} 
					name="input_2" 
					type="email" 
					placeholder="Email Address" 
					aria-invalid={(errors !== undefined) && errors.input_3 ? 'true' : 'false'}
					aria-describedby="error-email-required"
					{...register('input_2', { required: true })} />
				{(errors !== undefined) && errors.input_2 && errors.input_2.type === "required" && (
					<span role="alert" id="error-email-required">This field is required.</span>
				)}
			
				<input className={styleSheet.enews__submit} type="submit" value="Sign me up!" />
			</form> : null }

			{ formresponse !== "" ? <div className={styleSheet.formresponsemessage} dangerouslySetInnerHTML={{ __html: formresponse }}></div> : null }
		</>
	)
}

export default Enews;