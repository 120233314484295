import React from "react";
import { Link } from 'gatsby';
import Hours from "../hours";
import FixedImage from "../fixedImage";
import formatPhone from "../../helpers/formatPhone";
import WPLink from "../wpLink";

import * as styleSheet from "./index.module.scss"

const LocationCard = ({ location, arrowRedLocal, rightArrowYellowOffsite, rightArrowGray = false, isTeamPage = false, isAppointmentsPage = false }) => {
		function directionLink() {
			if (!location?.birdeyeLocation.location.lat || !location.birdeyeLocation.location.lng) {
				return;
			}

			let placeIdParam = '';

			if (location.ACFLocation.googlePlaceId) {
				placeIdParam = `&destination_place_id=${location.ACFLocation.googlePlaceId}`;
			}
			return <a className={styleSheet.directions} href={`https://www.google.com/maps/dir/?api=1&destination=${location.birdeyeLocation.location.lat} ,${location.birdeyeLocation.location.lng}${placeIdParam}`} target="_blank" rel="noopener noreferrer">Get Directions <FixedImage image={rightArrowYellowOffsite} passedClass={styleSheet.icon} /></a>
		}
	
  	return (
		<div className={isTeamPage ? styleSheet.LocationCardTeamPage : styleSheet.LocationCard}>
			<div className={styleSheet.top}>
				{ !isAppointmentsPage && <Link to={location.link} className={styleSheet.title} dangerouslySetInnerHTML={{__html: location.title}}></Link> }
				{ isAppointmentsPage && <Link to={location.link} className={styleSheet.titleappointments} dangerouslySetInnerHTML={{__html: location.title}}></Link> }
				{ location.birdeyeLocation.hoursOfOperations && !isAppointmentsPage && <Hours locationHours={location.birdeyeLocation.hoursOfOperations} utcOffset={location.birdeyeLocation.timezone} /> } 
			</div>
			<div className={styleSheet.bottom}>
				{ location.ACFLocation.briefDescription && <p dangerouslySetInnerHTML={{__html: location.ACFLocation.briefDescription.length > 100 ? location.ACFLocation.briefDescription.substring(0, 99) + "..." : location.ACFLocation.briefDescription}}></p>}
				{ location.birdeyeLocation.location.address1 && <span className={styleSheet.address} dangerouslySetInnerHTML={{__html: location.birdeyeLocation.location.address1 }}></span>}
				{ location.birdeyeLocation.location.address2 && <span className={styleSheet.address} dangerouslySetInnerHTML={{__html: location.birdeyeLocation.location.address2 }}></span>}
				{ location.birdeyeLocation.location.zip && location.birdeyeLocation.location.state && location.birdeyeLocation.location.city && <span className={styleSheet.address} dangerouslySetInnerHTML={{ __html: `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state} ${location.birdeyeLocation.location.zip}` }}></span>}
				{ directionLink() }
		
				{ location.birdeyeLocation.phone && <a className={styleSheet.phone} href={`tel:${location.birdeyeLocation.phone}`}>{formatPhone(location.birdeyeLocation.phone)}</a>}
				{ location.ACFLocation.bookMyAppointmentLink && !isAppointmentsPage && <WPLink link={location.ACFLocation.bookMyAppointmentLink} passedClass={styleSheet.link} icon={arrowRedLocal} />}
				{ location.ACFLocation.bookMyAppointmentLink && isAppointmentsPage && <Link to={location.ACFLocation.bookMyAppointmentLink.url} className={styleSheet.linkappointments} target="_blank">Book now <FixedImage image={arrowRedLocal} passedClass={styleSheet.iconhover} /> { rightArrowGray && <FixedImage image={rightArrowGray} passedClass={styleSheet.icon} />} </Link>}
				{ !location.ACFLocation.bookMyAppointmentLink && location.birdeyeLocation.phone && !isAppointmentsPage && <a className={styleSheet.link} href={`tel:${location.birdeyeLocation.phone}`}>Call Now</a>}
				{ !location.ACFLocation.bookMyAppointmentLink && location.birdeyeLocation.phone && isAppointmentsPage && <a className={styleSheet.linkappointments} href={`tel:${location.birdeyeLocation.phone}`}>Call Now</a>}
			</div>
		</div>
  	)
}

export default LocationCard;
