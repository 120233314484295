import React, { useEffect, useContext, useState } from "react";
import { Link } from 'gatsby';
import FluidImage from "../fluidImage";
import ThemeContext from '../../context/ThemeContext';

import * as styleSheet from "./index.module.scss"

const FullLengthDoctorImage = ({locations, dentists, arrowGrayLocal, arrowRedLocal}) => {
	const { location, getLocation } = useContext(ThemeContext);
	const [ nearestDoc, setNearestDoc ] = useState(null);
	const [ nearestLoc, setNearestLoc ] = useState(null);

	useEffect( () => {
	
		function sortByDistance(){

			let locationList = [];
	
			locations.forEach((wpLocation, i) => {   
				if( wpLocation.wpDentists && wpLocation.wpDentists.length > 0 && wpLocation.wpDentists[0] && wpLocation.wpDentists[0].ACFDentist.fullLengthImage ){
					// Only add locations where the first dentist at the location has a full length image
					const distance = getDistanceFromLatLonInMi(wpLocation.birdeyeLocation.location.lat, wpLocation.birdeyeLocation.location.lng, location.lat, location.lng);
					wpLocation.distance = distance;
					locationList.push(wpLocation);
				}
			});
	
			locationList.sort(function(a, b){
				var keyA = a.distance,
					keyB = b.distance;
				// Compare the 2 dates
				if(keyA < keyB) return -1;
				if(keyA > keyB) return 1;
				return 0;
			});

			if( locationList.length > 0 ){
				setNearestLoc(locationList[0]);
				setNearestDoc(locationList[0].wpDentists[0]);
			}
			
			function getDistanceFromLatLonInMi(lat1,lon1,lat2,lon2) {
				var R = 6371; // Radius of the earth in km
				var dLat = deg2rad(lat2-lat1);  // deg2rad below
				var dLon = deg2rad(lon2-lon1); 
				var a = 
					Math.sin(dLat/2) * Math.sin(dLat/2) +
					Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
					Math.sin(dLon/2) * Math.sin(dLon/2)
					; 
				var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
				var d = R * c; // Distance in km
				var miles = d * 0.621371;
				return miles;
			}
	
			function deg2rad(deg) {
				return deg * (Math.PI/180)
			}
		}

		if( location ){
			sortByDistance();
		} 
	}, [location, locations, dentists, getLocation])

	useEffect( () => {
		getLocation();
	}, [getLocation] )

	return (
		<div className={styleSheet.FullLengthDoctorImage}>
			{
				nearestDoc !== null && nearestDoc.ACFDentist.fullLengthImage && (
					<>
						<FluidImage data-sal="slide-up" data-sal-delay="1600" data-sal-easing="ease-in" passedClass={styleSheet.image} image={nearestDoc.ACFDentist.fullLengthImage} />
						<div className={styleSheet.info}>
							<Link to={nearestDoc.link} className={styleSheet.name}>{nearestDoc.title}</Link>
							{nearestLoc && <Link to={nearestLoc.link} className={styleSheet.location}>{nearestLoc.title}</Link> }
						</div>
					</>
				)
			}
		</div>
	)
}

export default FullLengthDoctorImage;
